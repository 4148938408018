<template>
  <div class="container">
    <div class="row mx-9">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="mouse">
              <div class="header-home-deals m-0 mouse text-noDecoration"> {{ $t("categories") }} </div>
              <div class="subheader-home"> {{ $t("line3") }} </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" v-if="categories.length > 0">
          <div class="col-auto mx-auto" v-for="category in categories" :key="category.id">
            <CategoryCard :category="category" />
          </div>
        </div>
        <NoPlaces v-else />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCard from "@/components/Listings/CategoryCard.vue";
import NoPlaces from "@/components/misc/NoPlaces";

export default {
  components: {
    CategoryCard,
    NoPlaces,
  },

  mounted() {
    this.$store
      .dispatch("getAllCategories", {
        lang: this.$i18n.locale,
        tree: 0,
      })
      .then((res) => {
        console.log(res);
      });
  },

  computed: {
    categories() {
      return this.$store.getters.all_categories;
    },
  },
};
</script>